import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NotificationRef, NotificationType } from '@app/dialog/models';
import { timer, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('notification', [
      state('void', style({ transform: 'translateY(-100%)', opacity: 0, display: 'block' })),
      state('*', style({ transform: 'translateY(0%)', opacity: 1, display: 'block' })),
      transition('* <=> *', [animate('300ms cubic-bezier(0.55, 0.30, 0.15, 0.90)')]),
    ]),
  ],
  standalone: true,
  imports: [NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, TranslateModule],
})
export class NotificationComponent implements OnInit {
  @HostBinding('@notification')
  public notification = true;

  public message: string;
  public type: NotificationType;
  public typeCSSClass: string;
  private timer: Subscription;

  constructor(private ref: NotificationRef) {
    this.message = this.ref.message;
    this.type = this.ref.type;

    switch (this.ref.type) {
      case NotificationType.INFO:
        this.typeCSSClass = 'is-info';
        break;
      case NotificationType.SUCCESS:
        this.typeCSSClass = 'is-success';
        break;
      case NotificationType.ERROR:
        this.typeCSSClass = 'is-error';
        break;

      default:
        this.typeCSSClass = 'is-info';
    }
  }

  ngOnInit(): void {
    if (this.ref.duration) {
      this.timer = timer(this.ref.duration).subscribe(() => this.close());
    }
  }

  public get NotificationType(): typeof NotificationType {
    return NotificationType;
  }

  public close(): void {
    this.ref.close();

    if (this.timer) {
      this.timer.unsubscribe();
    }
  }
}
