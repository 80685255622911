import { OverlayRef } from '@angular/cdk/overlay';
import { NotificationType } from '@app/dialog/models';

export class NotificationRef {

  constructor(
    public overlay: OverlayRef,
    public message: string,
    public type: NotificationType = NotificationType.ERROR,
    public duration: number = 6000
  ) { }

  public close(): void {
    this.overlay.detach();
    setTimeout(()=> this.overlay.dispose(), 300);
  }

}