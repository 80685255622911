/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { DialogConfig, DialogRef, DIALOG_DATA } from '@app/dialog/models';
import { DialogBaseComponent } from '@app/dialog/components';

let uniqueId = 0;

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public openDialogs: DialogRef[] = [];

  constructor(private _overlay: Overlay, private _injector: Injector) {}

  public open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    config?: DialogConfig<T>
  ): DialogRef<R> {
    const overlayConfig = new OverlayConfig({
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
      hasBackdrop: true,
    });

    const overlayRef = this._overlay.create(overlayConfig);
    const dialogRef = new DialogRef<R, T>(`dialog-${uniqueId++}`, overlayRef, content, config);
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    overlayRef.attach(new ComponentPortal(DialogBaseComponent, null, injector));
    this.openDialogs.push(dialogRef);

    dialogRef.afterClosed().subscribe(() => {
      const index = this.openDialogs.indexOf(dialogRef);

      if (index > -1) {
        this.openDialogs.splice(index, 1);
      }
    });

    return dialogRef;
  }
}
