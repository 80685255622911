import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { NotificationComponent } from '@app/dialog/components';
import { NotificationRef, NotificationType } from '@app/dialog/models';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private overlay: Overlay, private injector: Injector) { }

  public open(message: string, type?: NotificationType, duration?: number): void {
    const overlayRef = this.overlay.create();
    const notificationRef = new NotificationRef(overlayRef, message, type, duration);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: NotificationRef, useValue: notificationRef }
      ]
    });

    overlayRef.attach(new ComponentPortal(NotificationComponent, null, injector));
  }
  
  public openAsInfo(message: string, duration?: number): void {
    this.open(message, NotificationType.INFO, duration);
  }

  public openAsSuccess(message: string, duration?: number): void {
    this.open(message, NotificationType.SUCCESS, duration);
  }

  public openAsError(message: string, duration?: number): void {
    this.open(message, NotificationType.ERROR, duration);
  }

}
