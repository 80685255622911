/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, TemplateRef, Type, ViewEncapsulation } from '@angular/core';

import { DialogRef } from '@app/dialog/models';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('dialog', [
      state('void', style({ translate: '0 100%', opacity: 0 })),
      state('*', style({ translate: '0 0', opacity: 1 })),
      transition('* <=> *', [animate('300ms cubic-bezier(0.55, 0.30, 0.15, 0.90)')]),
    ]),
  ],
})
export class DialogBaseComponent {
  @HostBinding('[id]')
  public id: string = this._ref.id;

  @HostBinding('class')
  public class = 'dialog-container';

  @HostBinding('@dialog')
  public animation = true;

  public readonly content: string | TemplateRef<any> | Type<any>;

  public readonly width: string | undefined;
  public readonly height: string | undefined;

  constructor(private _ref: DialogRef) {
    this.content = this._ref.content;

    this.width = this._ref.config?.width;
    this.height = this._ref.config?.height;
  }

  public close(): void {
    this._ref.close();
  }
}
