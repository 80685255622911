<div class="notification" [ngClass]="typeCSSClass" (click)="close()">

  <ng-container [ngSwitch]="type">
    <i *ngSwitchCase="NotificationType.INFO" class="notification__pictogram"></i>
    <i *ngSwitchCase="NotificationType.SUCCESS" class="notification__pictogram teq-check"></i>
    <i *ngSwitchCase="NotificationType.ERROR" class="notification__pictogram teq-close"></i>
    <i *ngSwitchDefault class="notification__pictogram"></i>
  </ng-container>
  <div class="notification__body">
    <p class="notification__message">{{ message | translate }}</p>
    <i class="notification__icon teq-close"></i>
  </div>
</div>