/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateRef, Type } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { DialogConfig } from '@app/dialog/models';

export class DialogRef<R = any, T = any> {
  private readonly _afterClosed: Subject<R | undefined> = new Subject();

  constructor(
    public readonly id: string,
    public readonly overlay: OverlayRef,
    public readonly content: string | TemplateRef<any> | Type<any>,
    public readonly config?: DialogConfig<T>
  ) {}

  public close(dialogResult?: R | undefined): void {
    this.overlay.detach();
    setTimeout(() => this.overlay.dispose(), 300);

    this._afterClosed.next(dialogResult);
    this._afterClosed.complete();
  }

  public afterClosed(): Observable<R | undefined> {
    return this._afterClosed;
  }
}
